import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO title={data.wpPage.title} />
    <h1 dangerouslySetInnerHTML={{ __html: data.wpPage.title }} />
    <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      id
      title
      content
    }
  }
`